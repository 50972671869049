import {Component, Input} from '@angular/core';
import {Section} from '../../utils/Section';
import * as $ from 'jquery';

@Component({
    selector: 'section-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.less']
})

/**
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
export class NavComponent extends Section {
    slug = 'nav';


    set(lang: string, e: Event){
        this.language.set(lang)

        $("div#languages > a."+ this.active).removeClass(this.active)
        this.eltJ(e).addClass(this.active)
    }
}
