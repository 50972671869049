import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';

export class ThesaurusEN extends Thesaurus {
  slug = 'en';
  label = 'English';
  //...
  agreed = 'Agreed';
  alias = 'Alias (optional)';
  alias_title = 'You can replace the entry ID with a short text. Only lower case letters & minuses are allowed.';
  category = 'Category';
  categories = {
    benelux: 'Benelux',
    germany: 'Germany'
  };
  content = 'Content';
  copied = 'Copied';
  close = 'Close';
  cookies = 'To improve the functionality of our site we use cookies. By using the page you agree to the use of cookies.';
  company = 'Company';
  deletion_confirmation = 'You are about to delete the following article:';
  download_gpx = 'Download track';
  empty = 'Empty the form';
  error = 'Server error';
  errors: {
    categories_missing: 'The categories are missing.',
    start_missing: 'The start is missing.' ,
    track_missing: 'The track is missing.',
    access_denied: 'Access data denied. Your access data are invalid.',
    access_data_empty: 'The username or the password is empty.',
    update_failed: 'The article couldn\'t be updated.',
    insert_failed: 'The article couldn\'t be inserted.',
    action_missing: 'The action is missing.'
  };
  image = 'Head image';
  language = 'Language';
  load_more = 'Load more';
  login = 'Login';
  max_size = 'MAX: 16MB; File: ';
  meta = [
    {
      label: "Imprint",
      href: `${DataService.company_url}/${this.slug}/impressum`
    },
    {
      label: "Data Privacy",
      href: `${DataService.company_url}/${this.slug}/datenschutz`
    }
  ];
  more_info = 'More info';
  no_articles = 'No articles!';
  password = 'Password';
  preview = 'Preview';
  publish = 'Publish';
  publish_title = 'Check this box to publish the post.';
  read_more = 'Read more';
  region = 'Region';
  save = 'Save the data';
  save_notification = 'The data have been saved.';
  save_notification_warning = this.save_notification +' <strong>However not all files were uploaded.</strong>';
  title = 'Title';
  track = 'GPX track';
  username = 'Username';
}
