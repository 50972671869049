import {DataService} from '../utils/data.service';
import {Slugify} from '../utils/Slugify';

export class Thesaurus implements Slugify {
  static readonly _ = '???';
  static readonly project = DataService.company +' // GPX-Downloads';
  //...
  slug: string;
  label: string;
  //...

  //...
  agreed: any;
  alias: string;
  alias_title: string;
  category: string;
  categories: {};
  content: string;
  copied: string;
  close: string;
  cookies: string;
  company: string;
  deletion_confirmation: string;
  download_gpx: string;
  empty: string;
  error: string;
  errors: { categories_missing:string, start_missing:string, track_missing:string, access_denied:string, access_data_empty:string, update_failed:string, insert_failed:string, action_missing:string };
  readonly headline = 'GPX - Downloads';
  image: string;
  language: string;
  load_more: string;
  login: string;
  max_size: string;
  no_articles: string;
  meta: {label, href}[];
  more_info: string;
  password: string;
  preview: string;
  publish: string;
  publish_title: string;
  read_more: string;
  region: string;
  save: string;
  save_notification: string;
  save_notification_warning: string;
  readonly socials = [
    {
      slug: "blog",
      href: "https://sigma-inmotion.com"
    },
    {
      slug: "facebook",
      href: "https://www.facebook.com/SIGMASPORTGermany"
    },
    {
      slug: "instagram",
      href: "https://www.instagram.com/sigma_sport_germany"
    },
    {
      slug: "youtube",
      href: "https://www.youtube.com/user/SIGMAelektro"
    },
  ];

  title: string;
  track: string;
  username: string;
}
