/**
 * @requires CookieService
 *           @see https://medium.com/@jeroenouw/angular-8-how-to-use-cookies-14ab3f2e93fc
 *           #install npm install ngx-cookie-service
 */
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/Section';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../../utils/language.service';

@Component({
    selector: 'section-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.less'],
    encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})

export class CookiesComponent extends Section implements OnInit {
    slug = 'cookies';
    value = ''; //inactive by default
    private readonly key = this.slug +'-hint';


    constructor(private cookieService: CookieService){
        super()
    }

    ngOnInit() {
        super.ngOnInit();
        //DO NOT SWITCH
        //TRUE if the cookie used to control 'cookies-hint' is inactive; thus making the 'cookies-hint' ACTIVE
        if(this.cookieService.get(this.key) != '1') this.value = this.active
    }

    /**
     * this function is used to hide the cookies-hint container
     */
    hide(){
        this.cookieService.set(this.key, '1')
        this.value = '' //2 way biding update
    }
}

