import {Component, OnInit, ViewEncapsulation, HostListener, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import {HttpClient} from "@angular/common/http";
import { DataService } from "../../utils/data.service";
import {Page} from '../../utils/Page';
import {LanguageService} from '../../utils/language.service';


declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ArticlesComponent extends Page implements OnInit {
  protected admin = 0;

  private article: any;
  private container;
  private clipboard;
  private readonly attr = 'data-category';
  //readonly class = 'col-lg-12 col-xl-6 mb-5 article'; //col-md-12 col-lg-6 mb-5 article
  all_articles_loaded = false; //TRUE if all the articles have been loaded
  private scroll_loading = false; //TRUE if the articles are been loaded via scroll
  slug = 'articles';


  constructor(
    public language: LanguageService, private route: ActivatedRoute, protected http: HttpClient,
    public data: DataService,
    @Inject(DOCUMENT) private document: Document
  ){ super(language, http) }


  /**
   * this function is used to load the first n articles
   */
  ngOnInit(): void {
    super.ngOnInit();

    this.article = this.route.snapshot.paramMap.get("article"); //NULL if undefined
    //console.log(this.article)
    this.fetch(
      {action: "start", admin: this.admin, article: this.article},
      data => this.data.set(data) //TRUE if the server request was successfully parsed
    );

    this.container = document.getElementById("articles_container");
    this.clipboard = document.getElementById('clipboard_proxy')
  }

  /**
   * this function is the equivalent of jQuery.document.ready
   * it is implicitly invoked once the DOM has been fully loaded
   * @see https://angular.io/api/core/AfterViewInit
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    const interval = setInterval( //setInterval is required because this.fetch runs asynchronously
      () => {
        if(this.done){ //TRUE if the request to the server is complete (positive/negative response or error)
          //DO NOT MERGE
          if(!this.error){
            if(this.data.article) this.openModal( //article / to focus on
              this.language.theo.headline, //this.data.article.title,
              `<div class="article article-fullscreen ${this.active}" ${this.attr}="${this.data.article.category}">
                  <div class="h-100 img-box-center img-box-color">
                      <img class="img-fluid" src="${this.data.article.image}" alt="">
                  </div>
                  <div class="card-body">
                      <div class="row">
                          <h3 class="card-title col-md-12">${this.data.article.title}</h3>
                      </div>
                      <p class="card-text fullscreen">${this.data.article.content}</p>
                      ${this.data.article.track ? `<a class="btn btn-danger text-white source" href="${this.data.article.track}" target="_blank">${this.language.theo.download_gpx}</a>` : ''}
                  </div>
              </div>`
            );
            this.filter(); //activate all languages at the beginning
          }

          clearInterval(interval);
        }
      },
      500
    );
  }

  /**
   * @see https://brianflove.com/2016/10/10/angular-2-window-scroll-event-using-hostlistener/
   * @see https://stackoverflow.com/a/38252057/2652918
   */
  @HostListener("window:scroll", ['$event.target'])
  onWindowScroll() {
    //console.log(window.innerHeight, window.scrollY, this.document.documentElement.scrollTop, this.document.body.scrollTop, this.document.documentElement.offsetHeight, this.document.body.offsetHeight, this.document.documentElement.scrollHeight, this.document.body.scrollHeight)
    if(this.document.documentElement.scrollHeight - window.innerHeight - window.scrollY <= 100 && !this.scroll_loading){
      this.scroll_loading = true; //set the lock
      this.loadMore(false);
    }
  }

  /**
   * this function is used to copy the slug to the clipboard
   * @param alias {string}
   */
  copyToClipboard(alias: string){
    const url = this.url(alias)
    this.clipboard.value = url
    this.clipboard.select()
    document.execCommand("copy");
    alert(`${this.language.theo.copied}:\n${url}`)
  }

  /**
   *
   * @param event Event the click event
   */
  readMore(event: Event){
    //console.log(event)
    const elt = $(event.target);
    if(elt.hasClass("read-more")){
      const
        article = elt.parents('div.article')[0],
        title = article.querySelector('.card-title').innerHTML,
        downloader = article.querySelector('a.btn.source')
      ;

      this.openModal(
        this.language.theo.headline, //title,
  `<div class="article article-fullscreen ${this.active}">
              <div class="h-100 img-box-center img-box-color">
                  <img class="img-fluid" src="${article.querySelector('img.img-fluid').getAttribute('src')}" alt="">
              </div>
              <div class="card-body">
                  <div class="row">
                      <h3 class="card-title col-md-12">${title}</h3>
                  </div>
                  <p class="card-text fullscreen">${article.querySelector('p.card-text').innerHTML}</p>
                  ${downloader ? `<a class="btn btn-danger text-white source" href="${downloader.getAttribute('href')}" target="_blank">${this.language.theo.download_gpx}</a>` : ''}
              </div>
          </div>`
      );


      /* debugger
      const article = elt.parents('div.article')
      this.openModal('', article[0].outerHTML)
      */
    }
  }

  /**
   * this function is used to filter the entries based on the list of active languages
   */
  filter(){
    const categories = {};
    this.data.categories.forEach(category => categories[category.label] = category.active) //set the list of active categories

    this.data.articles.forEach(article => article.active = categories[article.category]) //update the visibility of the articles
  }


  /**
   * this function is used to load additional articles
   * @param scroll boolean TRUE if the scroll should be invoked once all the articles will have been loaded
   */
  loadMore(scroll: boolean){
    if(!this.all_articles_loaded) this.fetch(
      {action: 'load_more', admin: this.admin, offset: this.data.offset, categories: this.data.categories.map(category => category.label)},
      data => {
        if(data.articles.length == 0) this.all_articles_loaded = true;
        else{ //TRUE if the server request was successfully parsed
          DataService.transfer(data.articles, this.data.articles);

          /*
          //DO NOT SWITCH
          articles.forEach(article => {
            article = this.data.finalise(article)
            this.container.innerHTML += `<div class="${this.class}" data-category="${article.category}" data-round="${this.round}">
              <div class="card h-100">
                <div class="h-100 img-box-center img-box-color">
                  <img class="img-fluid" src="${article.image}" alt="">
                </div>
                <div class="card-body">
                  <h5 class="card-title" data-alias="${article.alias}">${article.title}</h5>
                  <h6 class="card-text">${article.region}</h6>
                  <p class="card-text">${article.content}</p>

                  ${article.track ? `<a class="btn btn-danger text-white source" href="server/articles.php?${article.track}" target="_blank">${this.language.theo.download_gpx}</a>` : ''}
                  <a class="btn btn-outline-danger read-more">${this.language.theo.read_more}</a>
                </div>
              </div>
            </div>`
          })
          */
          this.filter();
          this.data.offset = data.offset;

          //MUST COME AFTER `this.filter()`
          if(scroll) setTimeout( //used to give enough time to the AngularDOM to be done
            articles => {
              for(let i = 0; i < articles.length; i++){
                const elt = document.querySelector(`div.article.${this.active}[data-id='${articles[i].id}']`); //the first (visible) of the newly added articles
                if(elt){
                  this.scroller.animate({scrollTop: $(elt).offset().top}) //scroll to the first (visible) of the newly added articles
                  break; //break the loop after the first finding
                }
              }
            },
            500,
            data.articles
          );
          else this.scroll_loading = false; //release the scroll loading lock
        }
      }
    )
  }
}
