import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/**
 * PM (19.06.2019) @see https://angular.io/guide/http
 */
import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
// import { HttpClientJsonpModule} from '@angular/common/http';


//PM (04-05.07.2019)
import {CookieService} from 'ngx-cookie-service';
import {ArticlesComponent} from './pages/articles/articles.component';
import {ArticlesAdminComponent} from './pages/articles/articles.admin.component';
import {AdminComponent} from './pages/admin/admin.component';
import {CookiesComponent} from './sections/cookies/cookies.component';
import {NavComponent} from './sections/nav/nav.component';
import {FooterComponent} from './sections/footer/footer.component';
import {ModalComponent} from './sections/modal/modal.component';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    ArticlesComponent,
    ArticlesAdminComponent,
    AdminComponent,
    NavComponent,
    CookiesComponent,
    FooterComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
