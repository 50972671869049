import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';

export class ThesaurusDE extends Thesaurus {
  slug = 'de';
  label = 'Deutsch';
  //...
  agreed = 'Einverstanden';
  alias = 'Alias (optional)';
  alias_title = 'Sie können die Beitrags-ID durch einen kurzen Text ersetzen. Erlaubt sind nur Kleinbuchstaben & Minuse.';
  category = 'Kategorie';
  categories = {
    benelux: 'Benelux',
    germany: 'Deutschland'
  };
  content = 'Inhalt';
  copied = 'Kopiert';
  close = 'Schließen';
  cookies = 'Zur Verbesserung der Funktionalität unserer Seite benutzen wir Cookies. Mit der Nutzung der Seite erklären Sie sich mit der Verwendung der Cookies einverstanden.';
  company = 'Unternehmen';
  deletion_confirmation = 'Sie sind im Begriff den folgenden Beitrag zu löschen:';
  download_gpx = 'Track herunterladen';
  empty = 'Das Formular leeren';
  error = 'Serverfehler';
  errors: { //TODO translate
    categories_missing: 'The categories are missing.',
    start_missing: 'The start is missing.' ,
    track_missing: 'The track is missing.',
    access_denied: 'Access data denied. Your access data are invalid.',
    access_data_empty: 'The username or the password is empty.',
    update_failed: 'The article couldn\'t be updated.',
    insert_failed: 'The article couldn\'t be inserted.',
    action_missing: 'The action is missing.'
  };
  image = 'Headerbild';
  language = 'Sprache';
  load_more = 'Mehr laden';
  max_size = 'MAX: 16MB; Datei: ';
  login = 'Login';
  meta = [
    {
      label: "Impressum",
      href: `${DataService.company_url}/${this.slug}/impressum`
    },
    {
      label: "Datenschutz",
      href: `${DataService.company_url}/${this.slug}/datenschutz`
    }
  ];
  more_info = 'Mehr Info';
  no_articles = 'Keine Beiträge!';
  password = 'Passwort';
  preview = 'Vorschau';
  publish = 'Veröffentlichen';
  publish_title = 'Aktivieren Sie dieses Kontrollkästchen, um den Beitrag zu veröffentlichen.';
  read_more = 'Mehr lesen';
  region = 'Region';
  save = 'Die Daten speichern';
  save_notification = 'Die Daten wurden gespeichert.';
  save_notification_warning = this.save_notification +' <strong>Allerdings nicht alle Dateien konnten hochgeladen werden.</strong>';
  title = 'Title';
  track = 'GPX-Track';
  username = 'Benutzer';
}
