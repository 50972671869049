import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './pages/admin/admin.component';
import {ArticlesComponent} from './pages/articles/articles.component';
import {ArticlesAdminComponent} from './pages/articles/articles.admin.component';

/**
 * PM (19.06.2019)
 * @see https://angular.io/guide/router
 *      https://medium.com/@christo8989/angular-6-url-parameters-860db789db85
 *      https://medium.com/developing-an-angular-4-web-app/adding-routes-for-login-and-home-view-components-be676d786471
 *      https://www.sitepoint.com/component-routing-angular-router/ # children
 */
const routes: Routes = [
  /** #default has been changed */
  {path: '', component: ArticlesComponent, pathMatch: 'full'},
  {path: 'articles/:article', component: ArticlesComponent},
  {path: 'articles_admin', component: ArticlesAdminComponent},
  {path: 'admin', component: AdminComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
