import {ArticlesComponent} from "./articles.component";
import {Component, ViewEncapsulation} from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ArticlesAdminComponent extends ArticlesComponent { admin = 1 }
