/**
 * PM (04.07.2019)
 */
import {Element} from './Element';
import {LanguageService} from './language.service';
import * as $ from 'jquery';
import {AfterViewInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Thesaurus} from '../thesauri/thesaurus';

export class Page extends Element implements AfterViewInit {
  protected scroller: any;
  protected done: any;
  protected error: any;
  //private modal: {container, button, title, body};
  public modal = {title: Thesaurus._, body: Thesaurus._, container: null, button: null};


  constructor(public language: LanguageService, protected http: HttpClient){ super() }

  ngOnInit(){
    super.ngOnInit();
    this.scroller = $('html,body'); //html for safari, body for the rest of the major browsers
    this.language.set(); //DO NOT REMOVE THIS LINE: the language must be set before the html snippet are rendered
    this.setClickHandlers()
  }

  ngAfterViewInit(): void {
    this.modal = {
      container: document.getElementById("modal_container"),
      button: document.getElementById("modal_container_proxy"),
      title: Thesaurus._,
      body: Thesaurus._
    }
  }


  protected fetch(options, success){
    this.error = ''
    const
      successIn = data => {
        this.done = true
        if('error' in data){
          //@ts-ignore
          const error = data.error.trim();
          this.error = error.includes(' ') ? error : this.language.theo.errors[error];
          this.openModalError(this.error)
        }else success(data)
      },
      errorIn = error => {
        this.done = true
        this.error = error
        this.openModalError(error)
      },
      doneIn = (type) => console.log(`DONE: ${type}!!!`)

    if(options instanceof FormData){
      const xhr = new XMLHttpRequest()
      xhr.open('POST', 'server/articles.php')
      //xhr.setRequestHeader('Accept', 'application/json')
      //xhr.setRequestHeader('content-type', 'multipart/form-data')
      xhr.responseType = 'json' //implicit conversion
      xhr.onload = () => {
        if(xhr.status === 200) successIn(xhr.response)
        else errorIn(new Error(`${xhr.status}: ${xhr.statusText}`))
        doneIn('XMLHttpRequest')
      }
      xhr.send(options)

      /*
      fetch(
        'server/articles.php',
        {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            accept: 'application/json',
            'content-type': 'multipart/form-data'
          },
          redirect: 'error',
          referrerPolicy: 'origin',
          body: options
        }
      )
        .then(response => response.json())
        .then(successIn)
        .catch(errorIn)
        .then(() => doneIn('Fetch'))
      */
    }else{
      this.http
        .post(
          "server/articles.php",
          options,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json' //multipart/form-data
            }
          }
        )
        .subscribe(
          successIn,
          errorIn,
          () => doneIn('Regular')
        )
    }
  }

  /**
   * this function is used to get the URL of the given article
   * @param alias
   */
  protected url(alias: string): string {
    return window.location.href.replace(new RegExp(window.location.pathname +'$'), '/articles/'+ alias)
  }

  /**
   * this function is used to open the modal after updating it
   * @param title {string}
   * @param body {string}
   */
  protected openModal(title: string, body: string){
    this.modal.title = title;
    this.modal.body = body;
    this.modal.button.click() //open the modal by proxy
  }

  protected openModalError(body: string){ this.openModal(this.language.theo.error, body) }

  /**
   * PM (17.07.2019) standalone
   * this function is used to set the custom click events handler; among others
   * - the slider custom thumbnails
   * - the scrollable anchors
   */
  private setClickHandlers(){
    const active = this.active, scroller = this.scroller
    //DO NOT MERGE
    this.dom
      .on('click', 'ol.carousel-indicators > li', function(){
        //PM (17.07.2019) improvement due to the fact that more than one slider will now be presented some pages
        this.parentElement.querySelector(`li.${active}`).classList.remove(active) //remove the previously active thumbnail
        this.classList.add(active) //set the new active thumbnail

        //$('#thumbnail-list-01 > li.'+ active).removeClass(active);
        //$(e.currentTarget).addClass(active) //set the new active thumbnail
      })
      .on('click', `a.${this.prefix}scrollable[href]`, function(e){ //scroll event handler
        const query = this.getAttribute('href')
        if(query){
          e.preventDefault() //prevent the default behaviour

          const elt = document.querySelector(query)
          if(elt) scroller.animate({scrollTop: $(elt).offset().top}, 1000) //1sec
        }
      })
  }
}
