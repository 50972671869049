import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';

export class ThesaurusNL extends Thesaurus {
  slug = 'nl';
  label = 'Nederlands';
  //...
  agreed = 'Akkoord';
  alias = 'Alias (optioneel)';
  alias_title = 'U kunt de invoer-ID vervangen door een korte tekst. Alleen kleine letters en minnen zijn toegestaan.';
  category = 'Categorie';
  categories = {
    benelux: 'Benelux',
    germany: 'Duitsland'
  };
  content = 'Content';
  copied = 'Gekopieerd';
  close = 'Afsluiten';
  cookies = 'Ter verbetering van de functionaliteit van onze website gebruiken wij cookies. Door de website te gebruiken, gaat u akkoord met het gebruik van deze cookies.';
  company = 'Bedrijf';
  deletion_confirmation = 'Je wilt het volgende artikel deleten:';
  download_gpx = 'Download route';
  empty = 'Formulier leegmaken';
  error = 'Server error';
  errors: { //TODO translate
    categories_missing: 'The categories are missing.',
    start_missing: 'The start is missing.' ,
    track_missing: 'The track is missing.',
    access_denied: 'Access data denied. Your access data are invalid.',
    access_data_empty: 'The username or the password is empty.',
    update_failed: 'The article couldn\'t be updated.',
    insert_failed: 'The article couldn\'t be inserted.',
    action_missing: 'The action is missing.'
  };
  image = 'Hoofdafbeelding';
  language = 'Taal';
  load_more = 'Laad meer';
  login = 'Aanmelding';
  max_size = 'MAX: 16MB; Vijl: ';
  meta = [
    {
      label: "Impressie",
      href: `${DataService.company_url}/${this.slug}/impressum`
    },
    {
      label: "Data Privacy",
      href: `${DataService.company_url}/${this.slug}/datenschutz`
    }
  ];
  more_info = 'Meer info';
  no_articles = 'Geen artikelen!';
  password = 'Wachtwoord';
  preview = 'Voorbeeld';
  publish = 'Publiceer';
  publish_title = 'Check dit vak om de post te publiceren.';
  read_more = 'Lees meer';
  region = 'Regio';
  save = 'Data opslaan';
  save_notification = 'De data zijn opgeslagen.';
  save_notification_warning = this.save_notification +' <strong>Echter niet alle bestanden zijn geüpload.</strong>';
  title = 'Naam';
  track = 'GPX route';
  username = 'Gebruikersnaam';
}
