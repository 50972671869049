import {Component, ViewEncapsulation} from '@angular/core';
import * as $ from 'jquery';
import {Page} from '../../utils/Page';
import {LanguageService} from '../../utils/language.service';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../utils/data.service';
import {FileChangeEvent} from '@angular/compiler-cli/src/perform_watch';
import {Thesaurus} from '../../thesauri/thesaurus';

@Component({
  selector: 'app-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class AdminComponent extends Page {
  slug = 'admin';

  username: string;
  password: string;
  loggedIn = false; //used to switch the containers after the log in

  articles: {id, category, region, title, highlight}[]; //the articles list
  articlesFound = false; //used to switch the containers once the articles are loaded
  categories: {value, label}[];
  private list;
  form = {
    id: '', //hidden; used to differentiate existing articles from new ones
    active: false,
    alias: '',
    category: '',
    region: '',
    image: '',
    image_title: '',
    track: '',
    track_title: '',
    title: '',
    content: '',
    date_publication: ''
  };
  private readonly files: File[]; //0 -> image, 1 -> track
  notification: string;


  constructor(
    language: LanguageService, protected http: HttpClient,
    public data: DataService
  ){
    super(language, http);
    //SHOULD NOT SWITCH; th language is implicitly set in the line above

    this.categories = [{value: '', label: `-- ${this.language.theo.category} --`}];
    this.form.image = Thesaurus._; //unknown flag
    this.form.image_title = this.language.theo.max_size + Thesaurus._;
    this.form.track = Thesaurus._; //...
    this.form.track_title = this.language.theo.max_size + Thesaurus._;
    this.files = [null, null];
  }

  ngOnInit(): void {
    super.ngOnInit();

    if(DataService.devMode){ //this block is used on the development environment
      this.username = 'sigma_sport_micro_site_admin';
      this.password = 'AAw_VE4F+5BWT?1XeSpP2kdyg6AkJkHrO1';
      this.login()
    }
  }

  /**
   * this function is used to login
   */
  login(): void {
    this.fetch(
      {action: 'login', username: this.username, password: this.password},
      data => { //this block is only reachable if the validation was successful
        //empty the access_controller
        this.username = '';
        this.password = '';

        //load the articles
        if(data.articles.length > 0){
          data.articles.forEach(article => article.title = this.label(article));
          this.articles = data.articles;
          this.articlesFound = true;
        }

        //finalise the category select element
        this.categories = this.categories.concat( data.categories.map(category => { return {value: category, label: this.language.theo.categories[category]} }) );

        //DO NOT SWITCH
        this.loggedIn = true; //switch occurs after this attribute is updated by using the 2-way bidding function provided by Angular
      }
    )
  }

  /**
   * this function is used to load the given article
   * @param id the identifier of the article in question
   * @param event the click event
   */
  loadArticle(id: string, event: Event): void {
    this.notification = ''; //empty the notification area
    this.fetch(
      {action: 'load_article', id: id},
      data => { //TRUE if the article was successfully loaded
        //console.log(data)
        //pre-process
        data.active = data.active == 1; //SQL boolean to JS boolean
        data.image_title = this.language.theo.max_size + (data.image || Thesaurus._);
        data.image = data.image ? DataService.preview(data.image, 30, '') : Thesaurus._; //unknown flag
        data.track_title = this.language.theo.max_size + (data.track || Thesaurus._);
        data.track = data.track ? DataService.preview(data.track, 30, '') : Thesaurus._; //...
        this.files.forEach(file => file = null);

        for(let key in data) if(key in this.form) this.form[key] = data[key] || '';

        const /** @since PM (10.03.2020) */
          //@ts-ignore
          elt = this.elt(event).parentElement,
          //@ts-ignore
          eltActive = elt.parentElement.querySelector('.'+ this.active)
        ;
        if(eltActive) eltActive.classList.remove(this.active); //remove the previous active flag
        //@ts-ignore
        elt.classList.add(this.active); //set the new active flag
      }
    )
  }

  /**
   * this function is used to delete the given article
   * @param article s.e.
   * @param event the click event
   */
  deleteArticle(article: {id, title}, event: Event): void {
    if(confirm(`${this.language.theo.deletion_confirmation} ${article.title}`)) this.fetch(
      {action: 'delete_article', id: article.id},
      //@ts-ignore
      _ => { //TRUE if the deletion went as expected
        delete this.articles[article.id]
        //@ts-ignore
        this.elt(event).parentElement.remove()
      }
    )
  }

  /**
   * ``ngModel`` seems not to be useable by ``input[type=file]`` elements, it works but returns a security breach error on load; hence the work around below
   * this is a wrapper function; it is used to visualise the changes made by the user
   * @see https://medium.com/@amcdnl/file-uploads-with-angular-reactive-forms-960fd0b34cb5
   * @param event
   * @param i
   * @param key
   */
  updateFileUploader(event: FileChangeEvent | Event, i: number, key: string){
    // @ts-ignore
    this.files[i] = event.target.files[0];
    this.form[key] = this.files[i].name;

    // @ts-ignore
    //console.log(event.target.files)
  }

  /**
   * this function is used to either load or delete the given article
   * @param event
   */
  handleArticle(event: Event): void {
    this.notification = ''; //empty the notification area

    // @ts-ignore
    const elt = this.elt(event), classList = elt.classList;

    if(classList.contains('label')){ //TRUE if the article should be loaded
      this.fetch(
        // @ts-ignore
        {action: 'load_article', id: elt.getAttribute('data-id')},
        data => { //TRUE if the article was successfully loaded
          let elt;
          //for (let key in data) if( (elt = this.form.querySelector(`[name=${key}]`)) ) elt.value = data[key]
        }
      )
    }else if(classList.contains('close')){ //TRUE if the article should be deleted
      //DO NOT MERGE
      // @ts-ignore
      if(confirm(`${this.language.theo.deletion_confirmation} ${elt.previousElementSibling.innerHTML}`)) this.fetch(
        // @ts-ignore
        {action: 'delete_article', id: elt.getAttribute('data-id')},
        //@ts-ignore
        _ => elt.parentElement.remove() //TRUE if the deletion went as expected
      )
    }
  }

  /**
   * this function is used to empty the form
   */
  empty(): void {
    this.notification = ''; //empty the notification area

    //pre-process
    this.form.active = false;
    this.form.image = Thesaurus._; //unknown flag
    this.form.track = Thesaurus._; //...
    this.files.forEach(file => file = null);

    //the rest
    for(let key in this.form) if(key != 'active' && key != 'image' && key != 'track') this.form[key] = ''
  }


  /**
   * this function is used to save the data residing in the form, in the database;
   * either via an insert or an update
   * @see https://www.taniarascia.com/how-to-upload-files-to-a-server-with-plain-javascript-and-php/
   *      https://developer.mozilla.org/en-US/docs/Web/API/FormData
   *      https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML
   */
  save(): void {
    this.notification = ''; //empty the notification area

    //collect the data
    const data = new FormData();
    data.append('action', 'save_article')
    data.append('display', this.form.active ? '2' : '1');
    data.append('files[]', this.files[0]);
    data.append('files[]', this.files[1]);
    for(let key in this.form) if(key != 'active' && key != 'image' && key != 'track') data.append(key, this.form[key]);

    const data1 = {
      action: 'save_article',
      display: this.form.active ? '2' : '1',
      files: this.files,
    }
    for(let key in this.form) if(key != 'active' && key != 'image' && key != 'track') data1[key] = this.form[key]

    this.fetch(
      data,
      article => { //TRUE if everything went as expected
        for(let key in article) if(key in this.form) this.form[key] = article[key] || ''; //update the values

        let i;
        if(article.update){
          try{
            this.articles.forEach((article2, index) => {
              if(article2.id == article.id){
                article2.title = this.label(article);
                i = index;
                throw 'update_done'
              }
            })
          }catch(e){ if(e != 'update_done') throw e } //only the flag
        }else if(article.insert){ //insert at the top
          //this.list.insertAdjacentHTML('afterbegin', this.article(article)) //add at the top
          this.articles.splice(i = 0,0, article)
        }

        if(i !== undefined){ //highlight the article in the list
          this.articles[i].highlight = true;
          setTimeout(
            (article, notification) => {
              article.highlight = false;
              notification = ''
              //this.form.querySelectorAll('*[name]').forEach(elt => elt.value = ''); //empty the form
            },
            2000,
            this.articles[i], this.notification
          )
        }

        this.notification = article.warning ? this.language.theo.save_notification_warning : this.language.theo.save_notification; //notify the user
      }
    )
  }


  private label(article: {category, region, title}): string {
    return `${this.language.theo.categories[article.category]} // ${article.region} // ${article.title}`
  }

  private article(article: {id, category, region, title}): string {
    return `<div class="article" data-id="${article.id}">
      <span class="label" data-id="${article.id}">${this.label(article)}</span>
      <span class="close" data-id="${article.id}">&times;</span>
    </div>`
  }
}
